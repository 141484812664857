<template>
  <div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-w="3" vs-type="flex" vs-justify="center">
        <label for>Profile Image</label>
      </vs-col>

      <vs-col vs-w="3" vs-type="flex" vs-justify="center">
        <label for>AHPRA Certificate</label>
      </vs-col>

      <vs-col vs-w="3" vs-type="flex" vs-justify="center">
        <label for>Insurance Certificate</label>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-w="3" vs-type="flex" vs-justify="center">
        <a
          id="profileImage"
          v-if="userDetail.profileImage"
          :href="userDetail.profileImage"
          target="_blank"
        >
          <div class="img-wrapper">
            <!-- <vs-avatar size="110px" :src="userDetail.profileImage" /> -->
            <img :src="userDetail.profileImage" class="img-fluid" alt />
          </div>
        </a>

        <vs-avatar
          style="cursor: pointer"
          @click="open_in_new_tab(defaultPP)"
          size="110px"
          :src="defaultPP"
          v-else
        />
      </vs-col>

      <vs-col vs-w="3" vs-type="flex" vs-justify="center">
        <p
          v-if="!userDetail.ahpraCertificate"
          id="ahpraCertificate"
          class="cardDiv"
        >
          <span>N/A</span>
        </p>
        <a
          id="ahpraCertificate"
          v-else-if="determineFileType(userDetail.ahpraCertificate) !== 'pdf'"
          :href="userDetail.ahpraCertificate"
          target="_blank"
        >
          <div class="img-wrapper">
            <!-- <vs-avatar id="ahpraCertificate" size="110px" :src="userDetail.ahpraCertificate" /> -->
            <img :src="userDetail.ahpraCertificate" class="img-fluid" alt />
          </div>
        </a>
        <a
          class="cardDiv"
          id="ahpraCertificate"
          v-else
          :href="userDetail.ahpraCertificate"
          target="_blank"
        >
          <span>Click to view</span>
        </a>
      </vs-col>

      <vs-col vs-w="3" vs-type="flex" vs-justify="center">
        <p
          v-if="!userDetail.insuranceCertificate"
          class="cardDiv"
          id="insuranceCertificate"
        >
          <span>N/A</span>
        </p>

        <a
          id="insuranceCertificate"
          v-else-if="
            determineFileType(userDetail.insuranceCertificate) !== 'pdf'
          "
          :href="userDetail.insuranceCertificate"
          target="_blank"
        >
          <div class="img-wrapper">
            <img :src="userDetail.insuranceCertificate" class="img-fluid" alt />
            <!-- <vs-avatar id="insuranceCertificate" size="110px" :src="userDetail.insuranceCertificate" /> -->
          </div>
        </a>

        <a
          class="cardDiv"
          id="insuranceCertificate"
          v-else
          :href="userDetail.insuranceCertificate"
          target="_blank"
        >
          <span>Click to view</span>
        </a>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col
        id="profileImageUpload"
        class="vs-con-loading__container"
        vs-w="3"
      >
        <div class="m-3" style="text-align: center; position: relative">
          <label
            class="image-upload profile-image-upload"
            style="
              width: 60%;
              border: 1px solid #3dc9b3;
              display: inline-block;
              border-radius: 25px;
              padding: 5px;
            "
          >
            Change Photo
            <input
              type="file"
              accept="image/*"
              @change="
                uploadImage(
                  $event,
                  'profileImageUpload',
                  'profile-image-upload'
                )
              "
              id="file-input"
              hidden
            />
          </label>
        </div>
      </vs-col>

      <vs-col id="aphraUpload" class="vs-con-loading__container" vs-w="3">
        <div class="m-3" style="text-align: center; position: relative">
          <label
            class="image-upload aphra-image-upload"
            style="
              width: 60%;
              border: 1px solid #3dc9b3;
              display: inline-block;
              border-radius: 25px;
              padding: 5px;
            "
          >
            Change Certificate
            <input
              type="file"
              @change="uploadImage($event, 'aphraUpload', 'aphra-image-upload')"
              id="file-input"
              hidden
            />
          </label>
        </div>
      </vs-col>

      <vs-col id="insuranceUpload" class="vs-con-loading__container" vs-w="3">
        <div class="m-3" style="text-align: center; position: relative">
          <label
            class="image-upload insurance-image-upload"
            style="
              width: 60%;
              border: 1px solid #3dc9b3;
              display: inline-block;
              border-radius: 25px;
              padding: 5px;
            "
          >
            Change Certificate
            <input
              type="file"
              @change="
                uploadImage($event, 'insuranceUpload', 'insurance-image-upload')
              "
              id="file-input"
              hidden
            />
          </label>
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('firstName') ? true : false"
            :success="!errors.first('firstName') && userDetail.firstName !== ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="first name"
            name="firstName"
            label-placeholder="First Name"
            placeholder="First Name"
            v-model="userDetail.firstName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("firstName")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('lastName') ? true : false"
            :success="!errors.first('lastName') && userDetail.lastName !== ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="last name"
            name="lastName"
            label-placeholder="Last Name"
            placeholder="Last Name"
            v-model="userDetail.lastName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("lastName")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('contactNumber') ? true : false"
            :success="
              !errors.first('contactNumber') && userDetail.contactNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="contact number"
            name="contactNumber"
            label-placeholder="Contact Number"
            placeholder="Contact Number"
            v-model="userDetail.contactNumber"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("contactNumber")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label>Date Of Birth</label>
          <datepicker
            format="dd/MM/yyyy"
            v-model="userDetail.dateOfBirth"
            :input-class="{
              'is-true': !errors.has('dateOfBirth') && userDetail.dateOfBirth,
              'is-danger': errors.has('dateOfBirth'),
            }"
          >
            <template slot="afterDateInput">
              <span
                v-if="userDetail.dateOfBirth"
                class="
                  bg-color-done
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >done</i
                >
              </span>
              <span
                v-if="errors.has('dateOfBirth')"
                class="
                  bg-color-error
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >error</i
                >
              </span>
            </template>
          </datepicker>
          <span class="text-danger" style="font-size: 0.75em">
            {{ errors.first("dateOfBirth") }}
          </span>
          <input
            type="hidden"
            data-vv-as="Date of birth"
            data-vv-validate-on="change"
            name="dateOfBirth"
            v-validate="'required'"
            v-model="userDetail.dateOfBirth"
          />
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-validate-on="blur"
            data-vv-as="preferred name"
            name="preferredName"
            label-placeholder="Preferred Name (Optional)"
            placeholder="Preferred Name (optional)"
            v-model="userDetail.preferredName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("preferredName")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('ahpraRegistrationNumber') ? true : false"
            :success="
              !errors.first('ahpraRegistrationNumber') &&
              userDetail.ahpraRegistrationNumber !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="ahpra registration number"
            name="ahpraRegistrationNumber"
            label-placeholder="AHPRA Registration Number"
            placeholder="AHPRA Registration Number"
            v-model="userDetail.ahpraRegistrationNumber"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("ahpraRegistrationNumber")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label style="font-size: 10px; opacity: 0.5">AHPRA Exiry Date</label>
          <datepicker
            :disabled-dates="disabledDates"
            style="margin-top: 0 !important"
            :danger="errors.first('ahpraExiryDate') ? true : false"
            :success="
              !errors.first('ahpraExiryDate') &&
              userDetail.ahpraExiryDate !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-model="userDetail.ahpraExiryDate"
            name="ahpraExiryDate"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="ahpra exiry date"
            label-placeholder="Ahpra Exiry Date"
            placeholder="Ahpra Exiry Date"
            class="w-full mt-6"
            :format="customFormatter"
          ></datepicker>
          <span class="text-danger text-sm">{{
            errors.first("ahpraExiryDate")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('indemnityInsuranceProvider') ? true : false"
            :success="
              !errors.first('indemnityInsuranceProvider') &&
              userDetail.indemnityInsuranceProvider !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="indemnity insurance provider"
            name="indemnityInsuranceProvider"
            label-placeholder="Indemnity Insurance Provider"
            placeholder="Indemnity Insurance Provider"
            v-model="userDetail.indemnityInsuranceProvider"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("indemnityInsuranceProvider")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('indemnityInsuranceNumber') ? true : false"
            :success="
              !errors.first('indemnityInsuranceNumber') &&
              userDetail.indemnityInsuranceNumber !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="indemnity insurance number"
            name="indemnityInsuranceNumber"
            label-placeholder="Indemnity Insurance Number"
            placeholder="Indemnity Insurance Number"
            v-model="userDetail.indemnityInsuranceNumber"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("indemnityInsuranceNumber")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label style="font-size: 10px; opacity: 0.5"
            >Indemnity Insurance Expiry Date</label
          >
          <datepicker
            :disabled-dates="disabledDates"
            style="margin-top: 0 !important"
            :danger="errors.first('indemnityInsuranceExpiry') ? true : false"
            :success="
              !errors.first('indemnityInsuranceExpiry') &&
              userDetail.indemnityInsuranceExpiry !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-model="userDetail.indemnityInsuranceExpiry"
            name="indemnityInsuranceExpiry"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="indemnity insurance expiry date"
            label-placeholder="Indemnity Insurance Expiry Date"
            placeholder="Indemnity Insurance Expiry Date"
            class="w-full mt-6"
            :format="customFormatter"
          ></datepicker>
          <span class="text-danger text-sm">{{
            errors.first("indemnityInsuranceExpiry")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            v-if="userDetail.nurseCosmeticExperience !== ''"
            style="opacity: 0.5"
            >Brief outline of your cosmetic experience</label
          >
          <vs-textarea
            style="margin-top: 0"
            :danger="errors.first('nurseCosmeticExperience') ? true : false"
            :success="
              !errors.first('nurseCosmeticExperience') &&
              userDetail.nurseCosmeticExperience !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-validate-on="blur"
            data-vv-as="nurse cosmetic experience"
            name="nurseCosmeticExperience"
            label-placeholder="Nurse Cosmetic Experience"
            placeholder="Nurse Cosmetic Experience"
            v-model="userDetail.nurseCosmeticExperience"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">
            {{ errors.first("nurseCosmeticExperience") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('instagram') ? true : false"
            :success="!errors.first('instagram') && userDetail.instagram != ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate
            data-vv-validate-on="blur"
            data-vv-as="instagram"
            name="instagram"
            label-placeholder="instagram"
            placeholder="Instagram (Optional)"
            v-model="userDetail.instagram"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("instagram")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('facebook') ? true : false"
            :success="!errors.first('facebook') && userDetail.facebook != ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate
            data-vv-validate-on="blur"
            data-vv-as="facebook"
            name="facebook"
            label-placeholder="facebook"
            placeholder="Facebook (Optional)"
            v-model="userDetail.facebook"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("facebook")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('website') ? true : false"
            :success="!errors.first('website') && userDetail.website != ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate
            data-vv-validate-on="blur"
            data-vv-as="website"
            name="website"
            label-placeholder="website"
            placeholder="Website (Optional)"
            v-model="userDetail.website"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{ errors.first("website") }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('clinicName') ? true : false"
            :success="
              !errors.first('clinicName') && userDetail.clinicName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate
            data-vv-validate-on="blur"
            data-vv-as="clinic name"
            label-placeholder="Clinic Name"
            name="clinicName"
            placeholder="Clinic Name"
            v-model="userDetail.clinicName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">
            {{ errors.first("clinicName") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('clinicAddress1') ? true : false"
            :success="
              !errors.first('clinicAddress1') && userDetail.clinicAddress1 != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate
            data-vv-validate-on="blur"
            data-vv-as="Clinic Address 1"
            name="clinicAddress1"
            label-placeholder="Clinic Address 1"
            placeholder="Clinic Address 1 (Optional)"
            v-model="userDetail.clinicAddress1"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("clinicAddress1")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('city') ? true : false"
            :success="!errors.first('city') && userDetail.city != ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate
            data-vv-validate-on="blur"
            data-vv-as="city"
            label-placeholder="City"
            name="city"
            placeholder="City"
            v-model="userDetail.city"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">
            {{ errors.first("city") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('stateOrRegion') ? true : false"
            :success="
              !errors.first('stateOrRegion') && userDetail.stateOrRegion != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate
            data-vv-validate-on="blur"
            data-vv-as="state or region"
            label-placeholder="State/Region"
            name="stateOrRegion"
            placeholder="State/Region"
            v-model="userDetail.stateOrRegion"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">
            {{ errors.first("stateOrRegion") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <div :class="{ dateClass: !errors.has('photoId') }" style="">
            <label class="vs-input--label custom-label"
              >Attach Photo ID such as a drivers' licence or passport
              (Optional)</label
            >
            <div style="margin-top: 10px; margin-bottom: 10px">
              <div class="mb-4" v-if="userDetail.photoId">
                <div
                  v-if="determineFileType(userDetail.photoId) === 'pdf'"
                  style="margin: auto; width: 50%"
                >
                  <a :href="userDetail.photoId" target="_blank"
                    >Click here to view file</a
                  >
                </div>
                <div v-else style="margin: auto" class="register-img-wrapper">
                  <img :src="userDetail.photoId" class="img-fluid" />
                </div>
              </div>
              <div
                id="photoIdUpload"
                :class="{ 'mt-3': userDetail.photoId }"
                style="text-align: center; position: relative"
              >
                <label
                  id="photoId"
                  :class="{ 'six-pixel': errors.has('photoId') }"
                  class="image-upload photo-id-upload"
                  style="
                    border: 1px solid #cccccc;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px;
                  "
                >
                  + Attach Document
                  <input
                    type="file"
                    @change="
                      uploadImage($event, 'photoIdUpload', 'photo-id-upload')
                    "
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <span class="text-danger text-sm">
            {{ errors.first("photoId") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >Have you ever had any restrictions imposed on your ability to
            practice?</label
          >
          <vs-radio
            vs-value="Yes"
            vs-name="radios1"
            style="margin-right: 20px"
            v-model="userDetail.restrictionImposed"
            @change="validationKeys.showRestrictionImposeError = false"
            >Yes</vs-radio
          >
          <vs-radio
            vs-value="No"
            vs-name="radios1"
            v-model="userDetail.restrictionImposed"
            @change="validationKeys.showRestrictionImposeError = false"
            >No</vs-radio
          >
          <span
            v-if="validationKeys.showRestrictionImposeError"
            style="display: block"
            class="text-danger text-sm"
            >Please select one option.</span
          >
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >Change in registration (Do you agree to tell us within 48hrs of any
            change in your registration status?)</label
          >
          <vs-radio
            vs-value="Yes"
            vs-name="radios2"
            style="margin-right: 20px"
            v-model="userDetail.aggreementOnAhpraRegistrationChanged"
            @change="
              validationKeys.showAggreementOnAhpraRegistrationChangedError = false
            "
            >Yes</vs-radio
          >
          <vs-radio
            vs-value="No"
            vs-name="radios2"
            v-model="userDetail.aggreementOnAhpraRegistrationChanged"
            @change="
              validationKeys.showAggreementOnAhpraRegistrationChangedError = false
            "
            >No</vs-radio
          >
          <span
            v-if="validationKeys.showAggreementOnAhpraRegistrationChangedError"
            style="display: block"
            class="text-danger text-sm"
            >Please select one option.</span
          >
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >Change in insurance (Do you agree to tell us within 48hrs of any
            change in your insurance?)</label
          >
          <vs-radio
            vs-value="Yes"
            vs-name="radios3"
            style="margin-right: 20px"
            v-model="userDetail.aggreementOnIndemnityRegistrationChanged"
            @change="
              validationKeys.showAggreementOnIndemnityRegistrationChangedError = false
            "
            >Yes</vs-radio
          >
          <vs-radio
            vs-value="No"
            vs-name="radios3"
            v-model="userDetail.aggreementOnIndemnityRegistrationChanged"
            @change="
              validationKeys.showAggreementOnIndemnityRegistrationChangedError = false
            "
            >No</vs-radio
          >
          <span
            v-if="
              validationKeys.showAggreementOnIndemnityRegistrationChangedError
            "
            style="display: block"
            class="text-danger text-sm"
            >Please select one option.</span
          >
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >Are you an RN or an EN (If an EN, you must read the guidelines on
            supervision and acknowledge the requirements involved. Contact us
            for more detail if unsure.)</label
          >
          <vs-radio
            vs-value="RN"
            vs-name="radios4"
            style="margin-right: 20px"
            v-model="userDetail.rnOrEn"
            @change="validationKeys.showRnOrEnError = false"
            >RN</vs-radio
          >
          <vs-radio
            vs-value="EN"
            vs-name="radios4"
            v-model="userDetail.rnOrEn"
            @change="validationKeys.showRnOrEnError = false"
            >EN</vs-radio
          >
          <span
            v-if="validationKeys.showRnOrEnError"
            style="display: block"
            class="text-danger text-sm"
            >Please select one option.</span
          >
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >How many years of experience have you had as an RN or an EN?</label
          >
          <vs-input
            :danger="errors.first('expericedYearInRnOrEn') ? true : false"
            :success="
              !errors.first('expericedYearInRnOrEn') &&
              userDetail.expericedYearInRnOrEn != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'decimal:2'"
            data-vv-validate-on="blur"
            data-vv-as="year of experience in RN or EN"
            label-placeholder=""
            name="expericedYearInRnOrEn"
            placeholder=""
            v-model="userDetail.expericedYearInRnOrEn"
            class="w-full"
          />
          <span class="text-danger text-sm">
            {{ errors.first("expericedYearInRnOrEn") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >In what areas/fields?</label
          >
          <vs-input
            :danger="errors.first('areasForRnOrEn') ? true : false"
            :success="
              !errors.first('areasForRnOrEn') && userDetail.areasForRnOrEn != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate
            data-vv-validate-on="blur"
            data-vv-as="area of experience"
            label-placeholder=""
            name="areasForRnOrEn"
            placeholder=""
            v-model="userDetail.areasForRnOrEn"
            class="w-full"
          />
          <span class="text-danger text-sm">
            {{ errors.first("areasForRnOrEn") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >For how many years have you been a cosmetic injector?</label
          >
          <vs-input
            :danger="
              errors.first('cosmeticInjectorExperienceYears') ? true : false
            "
            :success="
              !errors.first('cosmeticInjectorExperienceYears') &&
              userDetail.cosmeticInjectorExperienceYears != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'decimal:2'"
            data-vv-validate-on="blur"
            data-vv-as="cosmetic injector experience year"
            label-placeholder=""
            name="cosmeticInjectorExperienceYears"
            placeholder=""
            v-model="userDetail.cosmeticInjectorExperienceYears"
            class="w-full"
          />
          <span class="text-danger text-sm">
            {{ errors.first("cosmeticInjectorExperienceYears") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
          >
            Please list any courses in cosmetic injectables that you have
            completed. (Optional)
          </label>
          <vs-textarea
            v-model="userDetail.cosmeticInjectableCourses"
            v-validate="'max:255'"
            name="cosmeticInjectableCourses"
            data-vv-validate-on="blur"
            data-vv-as="nurse injectable courses"
          />
          <span class="text-danger text-sm">
            {{ errors.first("cosmeticInjectableCourses") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <div
            :class="{ dateClass: !errors.has('trainingCertificate') }"
            style=""
          >
            <label class="vs-input--label custom-label"
              >Please upload any training completion certificates
              (Optional)</label
            >
            <div style="margin-top: 10px; margin-bottom: 10px">
              <div class="mb-4" v-if="userDetail.trainingCertificate">
                <div
                  v-if="
                    determineFileType(userDetail.trainingCertificate) === 'pdf'
                  "
                  style="margin: auto; width: 50%"
                >
                  <a :href="userDetail.trainingCertificate" target="_blank"
                    >Click here to view file</a
                  >
                </div>
                <div v-else style="margin: auto" class="register-img-wrapper">
                  <img
                    :src="userDetail.trainingCertificate"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div
                id="trainingCertificateUpload"
                :class="{ 'mt-3': userDetail.trainingCertificate }"
                style="text-align: center; position: relative"
              >
                <label
                  id="trainingCertificate"
                  :class="{ 'six-pixel': errors.has('trainingCertificate') }"
                  class="image-upload training-certificate-upload"
                  style="
                    border: 1px solid #cccccc;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px;
                  "
                >
                  + Attach Document
                  <input
                    type="file"
                    @change="
                      uploadImage(
                        $event,
                        'trainingCertificateUpload',
                        'training-certificate-upload'
                      )
                    "
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <span class="text-danger text-sm">
            {{ errors.first("trainingCertificate") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
          >
            Please list any other teaching that you have had in injectables.
            (For instance, from another nurse? A Doctor? On-the-job training?
            From a chain clinic? Please provide some detail (names, locations,
            durations))
          </label>
          <vs-textarea
            style="margin-bottom: 10px !important"
            v-model="userDetail.additionalTeachingInInjectables"
            v-validate="'max:255'"
            name="additionalTeachingInInjectables"
            data-vv-validate-on="blur"
            data-vv-as="additional injectable teachings"
          />
          <span class="text-danger text-sm">
            {{ errors.first("additionalTeachingInInjectables") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >On average, how many hours of in-clinic cosmetic practice per week
            do you currently do?</label
          >
          <vs-input
            :danger="
              errors.first('averageHourInCosmeticPracticePerWeek')
                ? true
                : false
            "
            :success="
              !errors.first('averageHourInCosmeticPracticePerWeek') &&
              userDetail.averageHourInCosmeticPracticePerWeek != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'decimal:2'"
            data-vv-validate-on="blur"
            data-vv-as="average hour in cosmetic practice per week"
            label-placeholder=""
            name="averageHourInCosmeticPracticePerWeek"
            placeholder=""
            v-model="userDetail.averageHourInCosmeticPracticePerWeek"
            class="w-full"
          />
          <span class="text-danger text-sm">
            {{ errors.first("averageHourInCosmeticPracticePerWeek") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >How many hours per week of in-clinic practice do you plan to
            do?</label
          >
          <vs-input
            :danger="
              errors.first('planForAverageHourInCosmeticPracticePerWeek')
                ? true
                : false
            "
            :success="
              !errors.first('planForAverageHourInCosmeticPracticePerWeek') &&
              userDetail.planForAverageHourInCosmeticPracticePerWeek != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'decimal:2'"
            data-vv-validate-on="blur"
            data-vv-as="plan for desired hours in cosmetic practice per week"
            label-placeholder=""
            name="planForAverageHourInCosmeticPracticePerWeek"
            placeholder=""
            v-model="userDetail.planForAverageHourInCosmeticPracticePerWeek"
            class="w-full"
          />
          <span class="text-danger text-sm">
            {{ errors.first("planForAverageHourInCosmeticPracticePerWeek") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >Roughly, how many patients do you think you have treated?</label
          >
          <vs-input
            :danger="errors.first('numberOfPatientsTreated') ? true : false"
            :success="
              !errors.first('numberOfPatientsTreated') &&
              userDetail.numberOfPatientsTreated != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'integer'"
            data-vv-validate-on="blur"
            data-vv-as="number of patients treated"
            label-placeholder=""
            name="numberOfPatientsTreated"
            placeholder=""
            v-model="userDetail.numberOfPatientsTreated"
            class="w-full"
          />
          <span class="text-danger text-sm">
            {{ errors.first("numberOfPatientsTreated") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-row>
            <label
              style="display: block"
              class="vs-input--label custom-label mb-1"
            >
              What are your confident areas for toxin treatment?
            </label>

            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForToxinTreatment.Glabella"
                @change="validationKeys.showToxinTreatmentError = false"
                >Glabella</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForToxinTreatment.Frontalis"
                @change="validationKeys.showToxinTreatmentError = false"
                >Frontalis</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForToxinTreatment[
                    'Lateral Orbicularis Oculi'
                  ]
                "
                @change="validationKeys.showToxinTreatmentError = false"
                >Lateral Orbicularis Oculi</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForToxinTreatment['Lip eversion']
                "
                @change="validationKeys.showToxinTreatmentError = false"
                >Lip eversion</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForToxinTreatment['Lipstick lines']
                "
                @change="validationKeys.showToxinTreatmentError = false"
                >Lipstick lines</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForToxinTreatment['Brow Lift']
                "
                @change="validationKeys.showToxinTreatmentError = false"
                >Brow Lift</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForToxinTreatment[
                    'Nasalis (bunny lines)'
                  ]
                "
                @change="validationKeys.showToxinTreatmentError = false"
                >Nasalis (bunny lines)</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForToxinTreatment[
                    'LLSAN (gummy smile)'
                  ]
                "
                @change="validationKeys.showToxinTreatmentError = false"
                >LLSAN (gummy smile)</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForToxinTreatment.Mentalis"
                @change="validationKeys.showToxinTreatmentError = false"
                >Mentalis</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForToxinTreatment.DAO"
                @change="validationKeys.showToxinTreatmentError = false"
                >DAO</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForToxinTreatment.Masseters"
                @change="validationKeys.showToxinTreatmentError = false"
                >Masseters</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForToxinTreatment.Hyperhidrosis
                "
                @change="validationKeys.showToxinTreatmentError = false"
                >Hyperhidrosis</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForToxinTreatment.Platysma"
                @change="validationKeys.showToxinTreatmentError = false"
                >Platysma</vs-checkbox
              >
            </vs-col>
          </vs-row>
          <span
            v-if="validationKeys.showToxinTreatmentError"
            style="display: block"
            class="text-danger text-sm"
            >Please select at least one option.</span
          >
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-row>
            <label
              style="display: block"
              class="vs-input--label custom-label mb-1"
            >
              What are your confident areas for filler treatment?
            </label>

            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForFillerTreatment['Lip filler']
                "
                @change="validationKeys.showFillerTreatmentError = false"
                >Lip filler</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForFillerTreatment.Chin"
                @change="validationKeys.showFillerTreatmentError = false"
                >Chin</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForFillerTreatment['Tear Trough']
                "
                @change="validationKeys.showFillerTreatmentError = false"
                >Tear Trough</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForFillerTreatment['Cheeks']"
                @change="validationKeys.showFillerTreatmentError = false"
                >Cheeks</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForFillerTreatment['Jawline']"
                @change="validationKeys.showFillerTreatmentError = false"
                >Jawline</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForFillerTreatment['Lip-stick lines']
                "
                @change="validationKeys.showFillerTreatmentError = false"
                >Lip-stick lines</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="userDetail.confidentAreasForFillerTreatment['NLF']"
                @change="validationKeys.showFillerTreatmentError = false"
                >NLF</vs-checkbox
              >
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-checkbox
                v-model="
                  userDetail.confidentAreasForFillerTreatment[
                    'Marionette Lines'
                  ]
                "
                @change="validationKeys.showFillerTreatmentError = false"
                >Marionette Lines</vs-checkbox
              >
            </vs-col>
          </vs-row>
          <span
            v-if="validationKeys.showFillerTreatmentError"
            style="display: block"
            class="text-danger text-sm"
            >Please select at least one option.</span
          >
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
          >
            Are there any areas that you are not confident in?
          </label>
          <vs-textarea
            style="margin-bottom: 10px !important"
            v-model="userDetail.areasNotConfidentIn"
            v-validate="'max:255'"
            name="areasNotConfidentIn"
            data-vv-validate-on="blur"
            data-vv-as="areas not confident in"
          />
          <span class="text-danger text-sm">
            {{ errors.first("areasNotConfidentIn") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
          >
            Are there any other areas, products or techniques are part of your
            practice?
          </label>
          <vs-textarea
            style="margin-bottom: 10px !important"
            v-model="userDetail.additionalAreaOfPractice"
            v-validate="'max:255'"
            name="additionalAreaOfPractice"
            data-vv-validate-on="blur"
            data-vv-as="additional areas, products or techniques of practice"
          />
          <span class="text-danger text-sm">
            {{ errors.first("additionalAreaOfPractice") }}
          </span>
        </div>
      </vs-col>

      <!--      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <div :class="{ dateClass: !errors.has('cv') }">
                  <label class="vs-input&#45;&#45;label custom-label"
                    >CV (Please upload a CV with at least two references)</label
                  >
                  <div style="margin-top:10px; margin-bottom:10px;">
                    <div class="mb-4" v-if="userDetail.cv">
                      <div
                        v-if="determineFileType(userDetail.cv) === 'pdf'"
                        style="margin:auto;width:50%;"
                      >
                        <a :href="userDetail.cv" target="_blank"
                          >Click here to view file</a
                        >
                      </div>
                      <div v-else style="margin:auto;" class="register-img-wrapper">
                        <img :src="userDetail.cv" class="img-fluid" />
                      </div>
                    </div>
                    <div
                      id="cvUpload"
                      :class="{ 'mt-3': userDetail.cv }"
                      style="text-align:center;position:relative;"
                    >
                      <label
                        id="cvId"
                        :class="{ 'six-pixel': errors.has('cv') }"
                        class="image-upload cv-upload"
                        style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                      >
                        + Attach Document
                        <input
                          type="file"
                          @change="uploadImage($event, 'cvUpload', 'cv-upload')"
                          id="file-input"
                          hidden
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <span class="text-danger text-sm">
                  {{ errors.first("cv") }}
                </span>
              </div>
            </vs-col>-->

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >Have you completed hyalase training in the last 12 months?</label
          >
          <vs-radio
            vs-value="Yes"
            vs-name="radios5"
            style="margin-right: 20px"
            v-model="userDetail.hasCompletedHyalaseTraningInLastTwelveMonth"
            @change="
              validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = false
            "
            >Yes</vs-radio
          >
          <vs-radio
            vs-value="No"
            vs-name="radios5"
            v-model="userDetail.hasCompletedHyalaseTraningInLastTwelveMonth"
            @change="
              validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = false
            "
            >No</vs-radio
          >
          <span
            v-if="
              validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth
            "
            style="display: block"
            class="text-danger text-sm"
            >Please select one option.</span
          >
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <div
            style=""
            :class="{
              dateClass: !errors.has(
                'hyalaseOrComplicationManagementCertificate'
              ),
            }"
          >
            <label
              style="display: block"
              class="vs-input--label custom-label mb-1"
              >Please upload any relevant certificates for hyalase/complication
              management (If you haven't done a course we recommend
              https://asi.braincert.com/lms/course/24241-AFI-1-0-Vascular-Complications-Emergencies#)</label
            >
            <div style="margin-top: 10px; margin-bottom: 10px">
              <div
                class="mb-4"
                v-if="userDetail.hyalaseOrComplicationManagementCertificate"
              >
                <div
                  v-if="
                    determineFileType(
                      userDetail.hyalaseOrComplicationManagementCertificate
                    ) === 'pdf'
                  "
                  style="margin: auto; width: 50%"
                >
                  <a
                    :href="
                      userDetail.hyalaseOrComplicationManagementCertificate
                    "
                    target="_blank"
                    >Click here to view file</a
                  >
                </div>
                <div v-else style="margin: auto" class="register-img-wrapper">
                  <img
                    :src="userDetail.hyalaseOrComplicationManagementCertificate"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div
                id="hyalaseManagenentCertificateUpload"
                :class="{
                  'mt-3': userDetail.hyalaseOrComplicationManagementCertificate,
                }"
                style="text-align: center; position: relative"
              >
                <label
                  id="hyalaseManagenentCertificateId"
                  :class="{
                    'six-pixel': errors.has(
                      'hyalaseOrComplicationManagementCertificate'
                    ),
                  }"
                  class="image-upload hyalase-managenent-certificate-upload"
                  style="
                    border: 1px solid #cccccc;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px;
                  "
                >
                  + Attach Document
                  <input
                    type="file"
                    @change="
                      uploadImage(
                        $event,
                        'hyalaseManagenentCertificateUpload',
                        'hyalase-managenent-certificate-upload'
                      )
                    "
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <span class="text-danger text-sm">
            {{ errors.first("hyalaseOrComplicationManagementCertificate") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label>Hyalase Training Expiration Date (maximum 1 year)</label>
          <datepicker
            :disabled-dates="disabledDates"
            format="dd/MM/yyyy"
            v-model="userDetail.hyalaseTrainingExpirationDate"
            :input-class="{
              'is-true':
                !errors.has('hyalaseTrainingExpirationDate') &&
                userDetail.hyalaseTrainingExpirationDate,
              'is-danger': errors.has('hyalaseTrainingExpirationDate'),
            }"
          >
            <template slot="afterDateInput">
              <span
                v-if="userDetail.hyalaseTrainingExpirationDate"
                class="
                  bg-color-done
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >done</i
                >
              </span>
              <span
                v-if="errors.has('hyalaseTrainingExpirationDate')"
                class="
                  bg-color-error
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >error</i
                >
              </span>
            </template>
          </datepicker>
          <span class="text-danger" style="font-size: 0.75em">
            {{ errors.first("hyalaseTrainingExpirationDate") }}
          </span>
          <input
            type="hidden"
            data-vv-as="hyalase training expiration date"
            data-vv-validate-on="change"
            name="hyalaseTrainingExpirationDate"
            v-validate
            v-model="userDetail.hyalaseTrainingExpirationDate"
          />
        </div>
      </vs-col>

      <!--
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <label
                  style="display: block;"
                  class="vs-input&#45;&#45;label custom-label mb-1"
                  >Have you completed Basic Life Support training (BLS) in the last 12
                  months?</label
                >
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios6"
                  style="margin-right: 20px;"
                  v-model="
                    userDetail.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth
                  "
                  @change="
                    validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = false
                  "
                  >Yes</vs-radio
                >
                <vs-radio
                  vs-value="No"
                  vs-name="radios6"
                  v-model="
                    userDetail.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth
                  "
                  @change="
                    validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = false
                  "
                  >No</vs-radio
                >
                <span
                  v-if="
                    validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth
                  "
                  style="display: block;"
                  class="text-danger text-sm"
                  >Please select one option.</span
                >
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <div style="" :class="{ dateClass: !errors.has('blsCertificate') }">
                  <label
                    style="display: block;"
                    class="vs-input&#45;&#45;label custom-label mb-1"
                    >Please upload your BLS certificate</label
                  >
                  <div style="margin-top:10px; margin-bottom:10px;">
                    <div class="mb-4" v-if="userDetail.blsCertificate">
                      <div
                        v-if="determineFileType(userDetail.blsCertificate) === 'pdf'"
                        style="margin:auto;width:50%;"
                      >
                        <a :href="userDetail.blsCertificate" target="_blank"
                          >Click here to view file</a
                        >
                      </div>
                      <div v-else style="margin:auto;" class="register-img-wrapper">
                        <img :src="userDetail.blsCertificate" class="img-fluid" />
                      </div>
                    </div>
                    <div
                      id="blsCertificateUpload"
                      :class="{ 'mt-3': userDetail.blsCertificate }"
                      style="text-align:center;position:relative;"
                    >
                      <label
                        id="blsCertificateId"
                        :class="{ 'six-pixel': errors.has('blsCertificate') }"
                        class="image-upload bls-certificate-upload"
                        style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                      >
                        + Attach Document
                        <input
                          type="file"
                          @change="
                            uploadImage(
                              $event,
                              'blsCertificateUpload',
                              'bls-certificate-upload'
                            )
                          "
                          id="file-input"
                          hidden
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <span class="text-danger text-sm">
                  {{ errors.first("blsCertificate") }}
                </span>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <label>BLS Training Expiration Date (maximum 1 year)</label>
                <datepicker
                  :disabled-dates="disabledDates"
                  format="dd/MM/yyyy"
                  v-model="userDetail.blsTrainingExpirationDate"
                  :input-class="{
                    'is-true':
                      !errors.has('blsTrainingExpirationDate') &&
                      userDetail.blsTrainingExpirationDate,
                    'is-danger': errors.has('blsTrainingExpirationDate')
                  }"
                >
                  <template slot="afterDateInput">
                    <span
                      v-if="userDetail.blsTrainingExpirationDate"
                      class="bg-color-done input-icon-validate vs-input&#45;&#45;icon-validate"
                    >
                      <i
                        valiconpack="material-icons"
                        class="vs-icon notranslate icon-scale material-icons null"
                        >done</i
                      >
                    </span>
                    <span
                      v-if="errors.has('blsTrainingExpirationDate')"
                      class="bg-color-error input-icon-validate vs-input&#45;&#45;icon-validate"
                    >
                      <i
                        valiconpack="material-icons"
                        class="vs-icon notranslate icon-scale material-icons null"
                        >error</i
                      >
                    </span>
                  </template>
                </datepicker>
                <span class="text-danger" style="font-size: 0.75em">
                  {{ errors.first("blsTrainingExpirationDate") }}
                </span>
                <input
                  type="hidden"
                  data-vv-as="bls training expiration date"
                  data-vv-validate-on="change"
                  name="blsTrainingExpirationDate"
                  v-validate="'required'"
                  v-model="userDetail.blsTrainingExpirationDate"
                />
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <label
                  style="display: block;"
                  class="vs-input&#45;&#45;label custom-label mb-1"
                  >Has immunity to Hepatitis B</label
                >
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios13"
                  style="margin-right: 20px;"
                  v-model="userDetail.hasImmunityToHepatitisB"
                  @change="validationKeys.showHasImmunityToHepatitisBError = false"
                  >Yes</vs-radio
                >
                <vs-radio
                  vs-value="No"
                  vs-name="radios13"
                  v-model="userDetail.hasImmunityToHepatitisB"
                  @change="validationKeys.showHasImmunityToHepatitisBError = false"
                  >No</vs-radio
                >
                <span
                  v-if="validationKeys.showHasImmunityToHepatitisBError"
                  style="display: block;"
                  class="text-danger text-sm"
                  >Please select one option.</span
                >
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <div
                  style=""
                  :class="{ dateClass: !errors.has('vaccinationCertificate') }"
                >
                  <label
                    style="display: block;"
                    class="vs-input&#45;&#45;label custom-label mb-1"
                    >Please upload your vaccination certification (We need to see
                    evidence of Hepatitis B immunity or vaccination)</label
                  >
                  <div style="margin-top:10px; margin-bottom:10px;">
                    <div class="mb-4" v-if="userDetail.vaccinationCertificate">
                      <div
                        v-if="
                          determineFileType(userDetail.vaccinationCertificate) ===
                            'pdf'
                        "
                        style="margin:auto;width:50%;"
                      >
                        <a :href="userDetail.vaccinationCertificate" target="_blank"
                          >Click here to view file</a
                        >
                      </div>
                      <div v-else style="margin:auto;" class="register-img-wrapper">
                        <img
                          :src="userDetail.vaccinationCertificate"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <div
                      id="vaccinationCertificateUpload"
                      :class="{ 'mt-3': userDetail.vaccinationCertificate }"
                      style="text-align:center;position:relative;"
                    >
                      <label
                        id="vaccinationCertificateId"
                        :class="{
                          'six-pixel': errors.has('vaccinationCertificate')
                        }"
                        class="image-upload vaccination-certificate-upload"
                        style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                      >
                        + Attach Document
                        <input
                          type="file"
                          @change="
                            uploadImage(
                              $event,
                              'vaccinationCertificateUpload',
                              'vaccination-certificate-upload'
                            )
                          "
                          id="file-input"
                          hidden
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <span class="text-danger text-sm">
                  {{ errors.first("vaccinationCertificate") }}
                </span>
              </div>
            </vs-col>
      -->

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >Have you read and agree to adhere to the Fresh Clinics "Clinical
            Standards" document?</label
          >
          <vs-radio
            vs-value="Yes"
            vs-name="radios7"
            style="margin-right: 20px"
            v-model="userDetail.hasReadClinicalStandardsDocuments"
            @change="
              validationKeys.showHasReadClinicalStandardsDocumentsError = false
            "
            >Yes</vs-radio
          >
          <vs-radio
            vs-value="No"
            vs-name="radios7"
            v-model="userDetail.hasReadClinicalStandardsDocuments"
            @change="
              validationKeys.showHasReadClinicalStandardsDocumentsError = false
            "
            >No</vs-radio
          >
          <span
            v-if="validationKeys.showHasReadClinicalStandardsDocumentsError"
            style="display: block"
            class="text-danger text-sm"
            >Please select one option.</span
          >
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >I confirm that I have made a true and accurate declaration.</label
          >
          <vs-radio
            vs-value="Yes"
            vs-name="radios8"
            style="margin-right: 20px"
            v-model="userDetail.hasConfirmedDeclarations"
            @change="validationKeys.showHasConfirmedDeclarationsError = false"
            >Yes</vs-radio
          >
          <vs-radio
            vs-value="No"
            vs-name="radios8"
            v-model="userDetail.hasConfirmedDeclarations"
            @change="validationKeys.showHasConfirmedDeclarationsError = false"
            >No</vs-radio
          >
          <span
            v-if="validationKeys.showHasConfirmedDeclarationsError"
            style="display: block"
            class="text-danger text-sm"
            >Please select one option.</span
          >
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
            >Are you happy for us to email you?</label
          >
          <vs-radio
            vs-value="Yes"
            vs-name="radios9"
            style="margin-right: 20px"
            v-model="userDetail.canSendEmail"
            @change="validationKeys.showCanSendEmailError = false"
            >Yes</vs-radio
          >
          <vs-radio
            vs-value="No"
            vs-name="radios9"
            v-model="userDetail.canSendEmail"
            @change="validationKeys.showCanSendEmailError = false"
            >No</vs-radio
          >
          <span
            v-if="validationKeys.showCanSendEmailError"
            style="display: block"
            class="text-danger text-sm"
            >Please select one option.</span
          >
        </div>
      </vs-col>

      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-button @click="saveUserDetails" :disabled="!validateForm"
              >Save Details</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vs-row>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { mapActions } from "vuex";
import Button from "../vuesax/button/Button";
import axios from "@/axios";
import { Validator } from "vee-validate";

export default {
  name: "NurseProfileEdit",
  props: ["userData"],
  components: {
    Button,
    Datepicker,
  },
  data() {
    return {
      disabledDates: { to: new Date() },
      defaultPP: require("@/assets/images/profile/default.png"),
      validationKeys: {
        showRestrictionImposeError: false,
        showAggreementOnAhpraRegistrationChangedError: false,
        showAggreementOnIndemnityRegistrationChangedError: false,
        showRnOrEnError: false,
        showToxinTreatmentError: false,
        showFillerTreatmentError: false,
        showHasCompletedHyalaseTraningInLastTwelveMonth: false,
        // showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth: false,
        showHasReadClinicalStandardsDocumentsError: false,
        showHasConfirmedDeclarationsError: false,
        // showHasImmunityToHepatitisBError: false,
        showCanSendEmailError: false,
      },
      userDetail: {
        id: "",
        firstName: "",
        lastName: "",
        nurseCosmeticExperience: "",
        preferredName: "",
        dateOfBirth: "",
        contactNumber: "",
        ahpraRegistrationNumber: "",
        ahpraExiryDate: "",
        indemnityInsuranceProvider: "",
        indemnityInsuranceNumber: "",
        indemnityInsuranceExpiry: "",
        ahpraCertificate: "",
        insuranceCertificate: "",
        userType: "",
        profileImage: "",
        clinicName: "",
        instagram: null,
        facebook: null,
        website: null,
        clinicAddress1: null,
        city: null,
        stateOrRegion: null,
        photoId: null,
        restrictionImposed: null,
        aggreementOnAhpraRegistrationChanged: null,
        aggreementOnIndemnityRegistrationChanged: null,
        rnOrEn: null,
        expericedYearInRnOrEn: null,
        areasForRnOrEn: null,
        cosmeticInjectorExperienceYears: null,
        cosmeticInjectableCourses: null,
        trainingCertificate: null,
        additionalTeachingInInjectables: null,
        averageHourInCosmeticPracticePerWeek: null,
        planForAverageHourInCosmeticPracticePerWeek: null,
        numberOfPatientsTreated: null,
        confidentAreasForToxinTreatment: {
          Glabella: false,
          Frontalis: false,
          "Lateral Orbicularis Oculi": false,
          "Lip eversion": false,
          "Lipstick lines": false,
          "Brow Lift": false,
          "Nasalis (bunny lines)": false,
          "LLSAN (gummy smile)": false,
          Mentalis: false,
          DAO: false,
          Masseters: false,
          Hyperhidrosis: false,
          Platysma: false,
        },
        confidentAreasForFillerTreatment: {
          "Lip filler": false,
          Chin: false,
          "Tear Trough": false,
          Cheeks: false,
          Jawline: false,
          "Lip-stick lines": false,
          NLF: false,
          "Marionette Lines": false,
        },
        areasNotConfidentIn: null,
        additionalAreaOfPractice: null,
        // cv: null,
        hasCompletedHyalaseTraningInLastTwelveMonth: null,
        hyalaseOrComplicationManagementCertificate: null,
        hyalaseTrainingExpirationDate: "",
        /*   blsTrainingExpirationDate: "",
           hasCompletedBasicLifeSupportTrainingInLastTwelveMonth: null,
           blsCertificate: null,
           vaccinationCertificate: null,
           hasImmunityToHepatitisB: null,*/
        hasReadClinicalStandardsDocuments: null,
        hasConfirmedDeclarations: null,
        canSendEmail: null,
      },
      file: "",
      tempProfileImage: "",
      tempAphraCertificate: "",
      tempInsuranceCertificate: "",
      tempSignatureImage: "",
      fetchedData: null,
    };
  },
  methods: {
    ...mapActions("general", ["updateNurseProfile"]),

    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },

    async saveUserDetails() {
      let isValid = await this.$validator.validateAll();

      if (
        moment(new Date(this.userDetail.dateOfBirth)).format("YYYY-MM-DD") >
        moment().subtract(18, "years").format("YYYY-MM-DD")
      ) {
        isValid = false;
        this.errors.add({
          field: "dateOfBirth",
          msg: "Your age must be 18 years or above",
        });
      } else {
        this.userDetail.dateOfBirth = moment(
          new Date(this.userDetail.dateOfBirth)
        ).format("YYYY-MM-DD");
        this.errors.remove("dateOfBirth");
      }

      /*if (
        moment(new Date(this.userDetail.hyalaseTrainingExpirationDate)).format(
          "YYYY-MM-DD"
        ) >
        moment()
          .add(1, "years")
          .format("YYYY-MM-DD")
      ) {
        isValid = false;
        this.errors.add({
          field: "hyalaseTrainingExpirationDate",
          msg: "Hyalase Training Expiration date cannot be greater than 1 year"
        });
      } else {
        this.userDetail.hyalaseTrainingExpirationDate = moment(
          new Date(this.userDetail.hyalaseTrainingExpirationDate)
        ).format("YYYY-MM-DD");
        this.errors.remove("hyalaseTrainingExpirationDate");
      }*/

      /*if (
        moment(new Date(this.userDetail.blsTrainingExpirationDate)).format(
          "YYYY-MM-DD"
        ) >
        moment()
          .add(1, "years")
          .format("YYYY-MM-DD")
      ) {
        isValid = false;
        this.errors.add({
          field: "blsTrainingExpirationDate",
          msg: "BLS Training Expiration date cannot be greater than 1 year"
        });
      } else {
        this.userDetail.blsTrainingExpirationDate = moment(
          new Date(this.userDetail.blsTrainingExpirationDate)
        ).format("YYYY-MM-DD");
        this.errors.remove("blsTrainingExpirationDate");
      }
*/
      /*if (!this.userDetail.restrictionImposed) {
        isValid = false;
        this.validationKeys.showRestrictionImposeError = true;
      } else {
        this.validationKeys.showRestrictionImposeError = false;
      }*/

      /* if (!this.userDetail.hasImmunityToHepatitisB) {
         isValid = false;
         this.validationKeys.showHasImmunityToHepatitisBError = true;
       } else {
         this.validationKeys.showHasImmunityToHepatitisBError = false;
       }*/

      if (!this.userDetail.canSendEmail) {
        isValid = false;
        this.validationKeys.showCanSendEmailError = true;
      } else {
        this.validationKeys.showCanSendEmailError = false;
      }

      if (!this.userDetail.hasConfirmedDeclarations) {
        isValid = false;
        this.validationKeys.showHasConfirmedDeclarationsError = true;
      } else {
        this.validationKeys.showHasConfirmedDeclarationsError = false;
      }

      if (!this.userDetail.hasReadClinicalStandardsDocuments) {
        isValid = false;
        this.validationKeys.showHasReadClinicalStandardsDocumentsError = true;
      } else {
        this.validationKeys.showHasReadClinicalStandardsDocumentsError = false;
      }

      /* if (
         !this.userDetail.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth
       ) {
         isValid = false;
         this.validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = true;
       } else {
         this.validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = false;
       }

       if (!this.userDetail.aggreementOnAhpraRegistrationChanged) {
         isValid = false;
         this.validationKeys.showAggreementOnAhpraRegistrationChangedError = true;
       } else {
         this.validationKeys.showAggreementOnAhpraRegistrationChangedError = false;
       }

       if (!this.userDetail.aggreementOnIndemnityRegistrationChanged) {
         isValid = false;
         this.validationKeys.showAggreementOnIndemnityRegistrationChangedError = true;
       } else {
         this.validationKeys.showAggreementOnIndemnityRegistrationChangedError = false;
       }

       if (!this.userDetail.hasCompletedHyalaseTraningInLastTwelveMonth) {
         isValid = false;
         this.validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = true;
       } else {
         this.validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = false;
       }

       if (!this.userDetail.rnOrEn) {
         isValid = false;
         this.validationKeys.showRnOrEnError = true;
       } else {
         this.validationKeys.showRnOrEnError = false;
       }

       var hasTrueKeys = Object.keys(
         this.userDetail.confidentAreasForToxinTreatment
       ).some(k => this.userDetail.confidentAreasForToxinTreatment[k]);

       if (!hasTrueKeys) {
         isValid = false;
         this.validationKeys.showToxinTreatmentError = true;
       } else {
         this.validationKeys.showToxinTreatmentError = false;
       }

       let checkFillerTrueKeys = Object.keys(
         this.userDetail.confidentAreasForFillerTreatment
       ).some(k => this.userDetail.confidentAreasForFillerTreatment[k]);
       if (!checkFillerTrueKeys) {
         isValid = false;
         this.validationKeys.showFillerTreatmentError = true;
       } else {
         this.validationKeys.showFillerTreatmentError = false;
       }*/

      /*if (!this.userDetail.cv) {
        this.errors.add({
          field: "cv",
          msg: "CV is required"
        });
        isValid = false;
        document.getElementById("cvId").style.borderColor = "#ea5455";
      } else {
        document.getElementById("cvId").style.borderColor = "#cccccc";
        this.errors.remove("cv");
      }*/

      /*if (!this.userDetail.hyalaseOrComplicationManagementCertificate) {
        this.errors.add({
          field: "hyalaseOrComplicationManagementCertificate",
          msg: "Hyalase or complication management certificate is required"
        });
        isValid = false;
        document.getElementById(
          "hyalaseManagenentCertificateId"
        ).style.borderColor = "#ea5455";
      } else {
        document.getElementById(
          "hyalaseManagenentCertificateId"
        ).style.borderColor = "#cccccc";
        this.errors.remove("hyalaseOrComplicationManagementCertificate");
      }

      if (!this.userDetail.blsCertificate) {
        this.errors.add({
          field: "blsCertificate",
          msg: "BLS certificate is required"
        });
        isValid = false;
        document.getElementById("blsCertificateId").style.borderColor =
          "#ea5455";
      } else {
        document.getElementById("blsCertificateId").style.borderColor =
          "#cccccc";
        this.errors.remove("blsCertificate");
      }

      if (!this.userDetail.vaccinationCertificate) {
        this.errors.add({
          field: "vaccinationCertificate",
          msg: "Vaccination certificate is required"
        });
        isValid = false;
        document.getElementById("vaccinationCertificateId").style.borderColor =
          "#ea5455";
      } else {
        document.getElementById("vaccinationCertificateId").style.borderColor =
          "#cccccc";
        this.errors.remove("vaccinationCertificate");
      }*/

      if (isValid) {
        let info = this.userDetail;
        this.$vs.loading();
        this.updateNurseProfile(info)
          .then((res) => {
            this.$vs.notify({
              title: "Success",
              text: "Profile updated successfully !",
              color: "success",
            });
            this.$emit("cancelEdit");
            this.$emit("showAlert");
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();

            let errorText = "Failed to update profile. Try again!";
            if (err && err.data && err.data.message)
              errorText = err.data.message;

            this.$vs.notify({
              title: "Failed",
              text: errorText,
              color: "danger",
            });

            if (err.status === 422) {
              this.mapServerErrors(this, err);
            }

            if (err.status === 417) {
              this.mapServerErrors(this, err);
            }
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    changePasswordHandler() {
      this.$router.push("/nurse/change-password");
    },
    assingUserDetailData(userData) {
      this.userDetail.id = userData.uid;
      this.userDetail.firstName = userData.firstName || "";
      this.userDetail.lastName = userData.lastName || "";
      this.userDetail.nurseCosmeticExperience =
        userData.nurseCosmeticExperience || "";
      this.userDetail.preferredName = userData.preferredName || "";
      this.userDetail.dateOfBirth = userData.dateOfBirth || "";
      this.userDetail.contactNumber = userData.contactNumber || "";
      this.userDetail.ahpraRegistrationNumber =
        userData.ahpraRegistrationNumber || "";
      this.userDetail.ahpraExiryDate = userData.ahpraExiryDate;
      this.userDetail.indemnityInsuranceProvider =
        userData.indemnityInsuranceProvider || "";
      this.userDetail.indemnityInsuranceNumber =
        userData.indemnityInsuranceNumber || "";
      this.userDetail.indemnityInsuranceExpiry =
        userData.indemnityInsuranceExpiry;
      this.userDetail.ahpraCertificate = userData.ahpraCertificate || "";
      this.userDetail.insuranceCertificate =
        userData.insuranceCertificate || "";
      this.userDetail.userType = userData.userType || "";
      this.userDetail.profileImage = userData.profileImage || "";
      this.userDetail.clinicName = userData.clinicName || "";
      this.userDetail.instagram = userData.instagram;
      this.userDetail.facebook = userData.facebook;
      this.userDetail.website = userData.website;
      this.userDetail.clinicAddress1 = userData.clinicAddress1;
      this.userDetail.city = userData.city;
      this.userDetail.stateOrRegion = userData.stateOrRegion;
      this.userDetail.photoId = userData.photoId;
      this.userDetail.restrictionImposed = userData.restrictionImposed;
      this.userDetail.aggreementOnAhpraRegistrationChanged =
        userData.aggreementOnAhpraRegistrationChanged;
      this.userDetail.aggreementOnIndemnityRegistrationChanged =
        userData.aggreementOnIndemnityRegistrationChanged;
      this.userDetail.rnOrEn = userData.rnOrEn;
      this.userDetail.expericedYearInRnOrEn = userData.expericedYearInRnOrEn;
      this.userDetail.areasForRnOrEn = userData.areasForRnOrEn;
      this.userDetail.cosmeticInjectorExperienceYears =
        userData.cosmeticInjectorExperienceYears;
      this.userDetail.cosmeticInjectableCourses =
        userData.cosmeticInjectableCourses;
      this.userDetail.trainingCertificate = userData.trainingCertificate;
      this.userDetail.additionalTeachingInInjectables =
        userData.additionalTeachingInInjectables;
      this.userDetail.averageHourInCosmeticPracticePerWeek =
        userData.averageHourInCosmeticPracticePerWeek;
      this.userDetail.planForAverageHourInCosmeticPracticePerWeek =
        userData.planForAverageHourInCosmeticPracticePerWeek;
      this.userDetail.numberOfPatientsTreated =
        userData.numberOfPatientsTreated;
      this.userDetail.confidentAreasForToxinTreatment =
        userData.confidentAreasForToxinTreatment
          ? userData.confidentAreasForToxinTreatment
          : {
              Glabella: false,
              Frontalis: false,
              "Lateral Orbicularis Oculi": false,
              "Lip eversion": false,
              "Lipstick lines": false,
              "Brow Lift": false,
              "Nasalis (bunny lines)": false,
              "LLSAN (gummy smile)": false,
              Mentalis: false,
              DAO: false,
              Masseters: false,
              Hyperhidrosis: false,
              Platysma: false,
            };
      this.userDetail.confidentAreasForFillerTreatment =
        userData.confidentAreasForFillerTreatment
          ? userData.confidentAreasForFillerTreatment
          : {
              "Lip filler": false,
              Chin: false,
              "Tear Trough": false,
              Cheeks: false,
              Jawline: false,
              "Lip-stick lines": false,
              NLF: false,
              "Marionette Lines": false,
            };
      this.userDetail.areasNotConfidentIn = userData.areasNotConfidentIn;
      this.userDetail.additionalAreaOfPractice =
        userData.additionalAreaOfPractice;
      // this.userDetail.cv = userData.cv;
      this.userDetail.hasCompletedHyalaseTraningInLastTwelveMonth =
        userData.hasCompletedHyalaseTraningInLastTwelveMonth;
      this.userDetail.hyalaseOrComplicationManagementCertificate =
        userData.hyalaseOrComplicationManagementCertificate;

      this.userDetail.hyalaseTrainingExpirationDate =
        userData.hyalaseTrainingExpirationDate || "";
      /*this.userDetail.blsTrainingExpirationDate =
        userData.blsTrainingExpirationDate || "";

      this.userDetail.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth =
        userData.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth;
      this.userDetail.blsCertificate = userData.blsCertificate;
      this.userDetail.vaccinationCertificate = userData.vaccinationCertificate;
      this.userDetail.hasImmunityToHepatitisB =
        userData.hasImmunityToHepatitisB;*/
      this.userDetail.hasReadClinicalStandardsDocuments =
        userData.hasReadClinicalStandardsDocuments;
      this.userDetail.hasConfirmedDeclarations =
        userData.hasConfirmedDeclarations;
      this.userDetail.canSendEmail = userData.canSendEmail;
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };

      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.userDetail.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            self.userDetail.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            self.userDetail.insuranceCertificate = res.data.Location;
          }
          if (key === "photoIdUpload") {
            this.errors.remove("photoId");
            document.getElementById("photoId").style.borderColor = "#cccccc";
            self.userDetail.photoId = res.data.Location;
          }
          if (key === "trainingCertificateUpload") {
            this.errors.remove("trainingCertificate");
            document.getElementById("trainingCertificate").style.borderColor =
              "#cccccc";
            self.userDetail.trainingCertificate = res.data.Location;
          }
          if (key === "cvUpload") {
            this.errors.remove("cv");
            document.getElementById("cvId").style.borderColor = "#cccccc";
            self.userDetail.cv = res.data.Location;
          }
          if (key === "hyalaseManagenentCertificateUpload") {
            this.errors.remove("hyalaseOrComplicationManagementCertificate");
            document.getElementById(
              "hyalaseManagenentCertificateId"
            ).style.borderColor = "#cccccc";
            self.userDetail.hyalaseOrComplicationManagementCertificate =
              res.data.Location;
          }
          if (key === "blsCertificateUpload") {
            this.errors.remove("blsCertificate");
            document.getElementById("blsCertificateId").style.borderColor =
              "#cccccc";
            self.userDetail.blsCertificate = res.data.Location;
          }
          if (key === "vaccinationCertificateUpload") {
            this.errors.remove("vaccinationCertificate");
            document.getElementById(
              "vaccinationCertificateId"
            ).style.borderColor = "#cccccc";
            self.userDetail.vaccinationCertificate = res.data.Location;
          }
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.response.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  created() {
    this.addressItems = [];
    this.$vs.loading();
    this.$store
      .dispatch(
        "general/getUserDetailForProfile",
        this.$store.state.AppActiveUser._id
      )
      .then((response) => {
        this.fetchedData = response.data.data;
        this.assingUserDetailData(this.fetchedData);
        this.$vs.loading.close();
      })
      .catch((err) => {
        this.$vs.loading.close();
      });
  },
  // watch: {
  //   "userDetail.restrictionImposed": function(newVal, oldVal) {
  //     alert(newVal);
  //     if (newVal) {
  //       this.validationKeys.showRestrictionImposeError = false;
  //     }
  //   },
  //   "userDetail.aggreementOnAhpraRegistrationChanged": function(
  //     newVal,
  //     oldVal
  //   ) {
  //     if (newVal) {
  //       this.validationKeys.showAggreementOnAhpraRegistrationChangedError = false;
  //     }
  //   },
  //   "userDetail.aggreementOnIndemnityRegistrationChanged": function(
  //     newVal,
  //     oldVal
  //   ) {
  //     if (newVal) {
  //       this.validationKeys.showAggreementOnIndemnityRegistrationChangedError = false;
  //     }
  //   },
  //   "userDetail.rnOrEn": function(newVal, oldVal) {
  //     if (newVal) {
  //       this.validationKeys.showRnOrEnError = false;
  //     }
  //   },
  //   "userDetail.hasCompletedHyalaseTraningInLastTwelveMonth": function(
  //     newVal,
  //     oldVal
  //   ) {
  //     if (newVal) {
  //       this.validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = false;
  //     }
  //   },
  //   "userDetail.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth": function(
  //     newVal,
  //     oldVal
  //   ) {
  //     if (newVal) {
  //       this.validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = false;
  //     }
  //   },
  //   "userDetail.hasReadClinicalStandardsDocuments": function(newVal, oldVal) {
  //     if (newVal) {
  //       this.validationKeys.showHasReadClinicalStandardsDocumentsError = false;
  //     }
  //   },
  //   "userDetail.hasConfirmedDeclarations": function(newVal, oldVal) {
  //     if (newVal) {
  //       this.validationKeys.showHasConfirmedDeclarationsError = false;
  //     }
  //   },
  //   "userDetail.canSendEmail": function(newVal, oldVal) {
  //     if (newVal) {
  //       this.validationKeys.showCanSendEmailError = false;
  //     }
  //   },
  //   "userDetail.confidentAreasForToxinTreatment": {
  //     handler: function(newVal, oldVal) {
  //       const checkTrueKeys = Object.keys(newVal).some(k => newVal[k]);
  //       if (checkTrueKeys) this.validationKeys.showToxinTreatmentError = false;
  //     },
  //     deep: true
  //   },
  //   "userDetail.confidentAreasForFillerTreatment": {
  //     handler: function(newVal, oldVal) {
  //       const checkTrueKeys = Object.keys(newVal).some(k => newVal[k]);
  //       if (checkTrueKeys) this.validationKeys.showFillerTreatmentError = false;
  //     },
  //     deep: true
  //   }
  // }
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
.fileContainer {
  overflow: hidden;
  position: relative;
  margin-top: 65px;
}

.fileContainer [type="file"] {
  cursor: pointer;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.image-upload {
  cursor: pointer;
}

.cardDiv {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border: 1px solid #3dc9b3;
  margin-top: 40px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  color: #3dc9b3;
}

.con-input-upload.disabled-upload {
  display: none;
}

.list-group {
  border: 1px solid #ddd;
  z-index: 99999;
  position: absolute;
  background: #fff;
}

.list-group li {
  padding: 5px 10px;
  cursor: pointer;
}

.vs-select-primary ul:last-child {
  display: none;
}

.con-input-upload {
  height: 100px;
  width: auto;
}

div.img-upload {
  height: 100px !important;
  width: auto !important;
}

div.img-upload img {
  height: 100% !important;
  width: 100% !important;
}

@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
